import React from "react";

// Files Import
import Faqs from "../../../components/Faqs/Faqs";
import StyledH3Heading from "../../../common/components/StyledH3Heading/StyledH3Heading";

const HomeFaqSection = () => {
  return (
    <div className="mx-auto py-20 md:w-11/12">
      <div className="text-center ">
        <StyledH3Heading
          fontColor={"text-black"}
          content={"Frequently Asked Questions"}
        />
        <p className="text-grey w-11/12 mx-auto text-sm md:text-lg lg:text-xl">
          Commonly asked questions and answers to them
        </p>
      </div>
      <Faqs />
    </div>
  );
};

export default HomeFaqSection;
