import React, { useEffect } from "react";

// Files Import
import { content } from "../../data";
import { Link, NavLink } from "react-router-dom";
import Copyrights from "../Copyrights/Copyrights";
import { usePath } from "../../context/PathContext";
import StyledMdText from "../../common/components/StyledMdText/StyledMdText";

export default function Footer() {
  const { path } = usePath();
  const footer = content.footerItems.footer;
  const Policy = content.Policy;
  const FooterSvg = content.FooterSvg;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    const arrowButton = document.getElementById("arrowButton");

    if (arrowButton) {
      arrowButton.style.opacity = window.scrollY > 100 ? "1" : "0";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavClick = () => {
    scrollToTop();
  };
  const styles = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "url('/assets/hero-section/pet-palette.png')",
  };

  return (
    <div
      style={styles}
      className="flex flex-col items-center justify-center w-full mx-auto text-white bg-darkBlue"
    >
      <div className="flex justify-between items-start w-4/5 md:w-5/6 lg:w-4/5 pt-[30px] sm:pt-[70px] pb-[70px]">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between md:items-start w-full lg:w-[75%] xl:w-[70%] gap-10">
          {/* left */}
          <div className="flex flex-col pb-6 md:items-start gap-14 sm:gap-7">
            {/*LOGO*/}
            <Link to={"/"} className="cursor-pointer">
              <img src="/assets/footer-logo.svg" alt="Paw Play Love Logo" />
            </Link>
            {/*Contact*/}
            <div className="flex flex-col items-start gap-2">
              <Link
                to="tel:(123) 456 789 10"
                className="text-sm cursor-pointer text-lightGrey hover:underline"
              >
                (123) 456 789 10
              </Link>
              <Link
                to="mailto:info@pawplaylove.com"
                className="text-sm cursor-pointer text-lightGrey hover:underline"
              >
                info@pawplaylove.com
              </Link>
            </div>
          </div>
          <div className="flex flex-row items-start justify-between sm:w-[50%] lg:w-[45%] gap-10">
            {/* center */}
            <div className="flex flex-col gap-5">
              <StyledMdText fontColor={"text-white !font-semibold"} content={"Company"} />
              <ul className="flex flex-col items-start gap-2">
                {footer.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    onClick={handleNavClick}
                    className={`text-white cursor-pointer font-normal text-sm hover:underline ${
                      path === item.link ? "underline" : ""
                    }`}
                  >
                    {item.menu}
                  </NavLink>
                ))}
              </ul>
            </div>
            {/* right */}
            <div className="flex flex-col items-start gap-5">
              <StyledMdText fontColor={"text-white !font-semibold"} content={"Legal"} />
              <ul className="flex flex-col items-start gap-2">
                {Policy.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.link}
                    onClick={handleNavClick}
                    className={`text-white cursor-pointer font-normal text-sm hover:underline ${
                      path === item.link ? "underline" : ""
                    }`}
                  >
                    {item.menu}
                  </NavLink>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* Arrow */}
        <div
          className="items-center justify-center hidden transition duration-300 ease-in-out cursor-pointer lg:flex hover:scale-110"
          onClick={scrollToTop}
        >
          <img src="/assets/up-arrow.svg" alt="Up Arrow" />
        </div>
      </div>
      <hr className="w-3/4 text-white md:w-5/6 lg:w-4/5" />
      {/* Copyrights */}
      <div className="flex flex-col items-center gap-4 py-10">
        <Copyrights />
        {/* Social icons */}
        <div className="flex gap-3">
          {FooterSvg.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              target="_blank"
              className="transition duration-300 ease-in-out hover:scale-110"
            >
              {item.icon}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
