import React from "react";

const StyledH1Heading = ({ fontColor, content }) => {
  return (
    <h1
      className={`${fontColor} text-h1 font-bold lg:font-extrabold leading-tight`}
    >
      {content}
    </h1>
  );
};

export default StyledH1Heading;
