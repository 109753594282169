import React from "react";

// Files Import
import { content } from "../../../data";
import StyledMdText from "../../../common/components/StyledMdText/StyledMdText";
import StyledLgText from "../../../common/components/StyledLgText/StyledLgText";
import StyledH2Heading from "../../../common/components/StyledH2Heading/StyledH2Heading";

const PetPortalSection = () => {
  const PetPortalSection = content.PetPortalSection;
  return (
    <div className="flex flex-col items-center justify-center w-11/12 py-16 mx-auto lg:w-full ">
      <div className="flex flex-col items-center text-center pb-14">
        <StyledH2Heading
          fontColor={"text-black"}
          content={"Why choose Paw Play Love"}
        />
        <StyledMdText
          content={"Connecting Pet Owners Through the Love for Animals"}
          fontColor={
            "text-sm leading-normal text-grey md:text-lg lg:text-xl sm:w-2/3"
          }
        ></StyledMdText>
      </div>
      <div className="flex flex-col items-center justify-center gap-10 mx-auto w-11/12 sm:w-[80%]">
        {PetPortalSection.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col lg:flex-row items-center justify-between gap-5 md:gap-10 lg:gap-14 xl:gap-20 ${
              index % 2 === 0 ? "lg:flex-row-reverse" : ""
            }`}
          >
            <div className="flex justify-center w-full">
              <img src={item.imgUrl} alt="Paw Play Love" />
            </div>
            <div className="lg:w-[80%]">
              <StyledLgText fontColor="" content={item.content} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PetPortalSection;
