import React from "react";

// Files Import
import { content } from "../../data";
import ContactSection from "../../components/ContactUs/ContactUs";
import StyledMdText from "../../common/components/StyledMdText/StyledMdText";
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";
import StyledH6Heading from "../../common/components/StyledH6Heading/StyledH6Heading";
import SupportGradient from "../../components/Gradients/SupportGradient/SupportGradient";

export default function SupportSection() {
  const support = content.support;
  return (
    <div className="flex flex-col gap-5 py-10 mx-auto">
      <SupportGradient>
        <div className="flex flex-col sm:flex-row justify-between items-center w-[85%] mx-auto py-10 gap-6">
          <div className="sm:w-7/12 md:w-3/6">
            <StyledH2Heading fontColor={"text-black"} content={"Support"} />
            <p className="text-darkGrey">
              Guidance for Pet Owners: Navigating the PetMate Experience
              Hassle-Free, Every Step of the Way.
            </p>
          </div>
          <div>
            <img src="/assets/support-pets.png" alt="Pets" />
          </div>
        </div>
      </SupportGradient>
      <div className="bg-lightGrey flex flex-col gap-5 rounded-[18px] py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {support?.map((item, index) => (
          <div key={index} className="w-[85%] mx-auto flex flex-col gap-4">
            <StyledH6Heading fontColor={"text-black"} content={item.head} />
            <StyledMdText fontColor={"text-darkGrey"} content={item.desc} />
          </div>
        ))}
      </div>
      <div className="w-full">
        <ContactSection />
      </div>
    </div>
  );
}
