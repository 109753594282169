import React, { useState, useEffect } from "react";

// Files Import
import { content } from "../../data";
import { Link } from "react-router-dom";

const Faqs = ({ searchTerm = "" }) => {
  const faqs = content.faqs;

  const [openItems, setOpenItems] = useState([]);

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggle = (index) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter((item) => item !== index);
      } else {
        return [...prevOpenItems, index];
      }
    });
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      const filteredIndexes = filteredFaqs.map((_, index) => index);
      setOpenItems(filteredIndexes);
    } else {
      setOpenItems([]);
    }
  }, [searchTerm]);

  return (
    <div className="flex items-center justify-center">
      <ul className="mx-auto w-11/12 md:w-3/4 lg:w-[62%] flex flex-col gap-3 py-8">
        {filteredFaqs.length > 0 ? (
          filteredFaqs.map((item, index) => (
            <li
              key={index}
              className={`overflow-hidden divide-y shadow-md shadow-blue-600 rounded-2xl bg-lightGrey ${
                openItems.includes(index) ? "text-purple" : ""
              }`}
            >
              <div className="group border-l-[10px] border-purple pl-4 md:pl-5">
                <summary
                  className="relative flex items-center gap-3 py-2 font-medium md:py-4 marker:content-none hover:cursor-pointer"
                  onClick={() => handleToggle(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="absolute w-5 h-5 font-extrabold transition group-open:text-purple group-open:rotate-180 top-5 right-3"
                  >
                    <path
                      d="M17.2997 9.30001C17.483 9.48335 17.5747 9.71668 17.5747 10C17.5747 10.2833 17.483 10.5167 17.2997 10.7L12.6997 15.3C12.5997 15.4 12.4914 15.471 12.3747 15.513C12.258 15.5543 12.133 15.575 11.9997 15.575C11.8664 15.575 11.737 15.55 11.6117 15.5C11.487 15.45 11.383 15.3833 11.2997 15.3L6.69971 10.7C6.51637 10.5167 6.42471 10.2833 6.42471 10C6.42471 9.71668 6.51637 9.48335 6.69971 9.30001C6.88304 9.11668 7.11637 9.02501 7.39971 9.02501C7.68304 9.02501 7.91637 9.11668 8.09971 9.30001L11.9997 13.2L15.8997 9.30001C16.083 9.11668 16.3164 9.02501 16.5997 9.02501C16.883 9.02501 17.1164 9.11668 17.2997 9.30001Z"
                      fill="currentColor"
                    />
                  </svg>

                  <span className={`group-open:text-purple font-semibold`}>
                    {item.question}
                  </span>
                </summary>

                {openItems.includes(index) && (
                  <article className="px-2 sm:px-4 pb-4 text-darkGrey text-sm w-[95%]">
                    <p className="text-[15px]">{item.answer}</p>
                  </article>
                )}
              </div>
            </li>
          ))
        ) : (
          <div className="flex flex-col gap-5 justify-center items-center">
            <img src="/assets/no-result-found.svg" alt="Result Not Found" />
            <p className="text-center text-darkGrey text-lg w-full sm:w-4/5 xl:w-[55%]">
              Sorry, the question you are looking for was not found. But you can
              ask anything from us.{" "}
              <Link
                className="font-bold text-[#A029F1] hover:text-[#a129f1d8]"
                to={"/support"}
              >
                Contact Us
              </Link>
            </p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Faqs;
