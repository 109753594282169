import React, { useState } from "react";

// Files Import
import Faqs from "../../components/Faqs/Faqs";
import SearchBar from "../../components/SearchBar/SearchBar";
import StyledH3Heading from "../../common/components/StyledH3Heading/StyledH3Heading";
import SupportGradient from "../../components/Gradients/SupportGradient/SupportGradient";

export default function FaqSection() {
  const [userInput, setUserInput] = useState("");
  const handleChange = (e) => {
    setUserInput(e.target.value);
  };
  return (
    <div className="py-10 mx-auto">
      <SupportGradient>
        <div className="py-10 text-center">
          <StyledH3Heading
            fontColor={"text-black"}
            content={"Frequently Asked Questions"}
          />
          <p className="w-11/12 mx-auto text-grey sm:w-9/12 lg:w-8/12">
            You can find your answers to your problems here or simply search for
            the exact question, you have in your mind.
          </p>
          <SearchBar onChange={handleChange} value={userInput} />
        </div>
      </SupportGradient>
      <Faqs searchTerm={userInput} />
    </div>
  );
}
