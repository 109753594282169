import React from "react";

// Files Import
import PawPlayBond from "../../sections/About/PawPlayBond/PawPlayBond";
import GetAppSection from "../../sections/Home/GetAppSection/GetAppSection";
import AboutHeroSection from "../../sections/About/AboutHeroSection/AboutHeroSection";
import PetPortalSection from "../../sections/About/PetPortalSection/PetPortalSection";
import PawPlayLoveSection from "../../sections/About/PawPlayLoveSection/PawPlayLoveSection";

const AboutPage = () => {
  return (
    <div className="">
      <AboutHeroSection />
      <PetPortalSection />
      <PawPlayBond />
      <PawPlayLoveSection />
      <GetAppSection />
    </div>
  );
};

export default AboutPage;
