import React from "react";

const SupportGradient = ({ children }) => {
  return (
    <div className="max-w-inset-0 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
      <div className="bg-support-bgGradient rounded-[18px]">{children}</div>
    </div>
  );
};

export default SupportGradient;
