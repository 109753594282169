import React from "react";

const EngageZoneSection = () => {
  return (
    <div className="relative flex flex-col items-center justify-center w-full md:flex-row">
      <div className="w-full md:w-[30%] self-stretch bg-[#8D83FF] flex justify-center text-center items-center md:justify-start md:text-start ">
        <div className="w-fit md:pl-[8%] lg:pl-[6%] xl:pl-[7%] py-8 text-white font-extrabold uppercase text-[50px] md:text-[7vw] xl:text-[8vw] 2xl:text-[8vw] md:absolute left-[6%] lg:left-[8%] xl:left-[4.5%] top-[15%] 2xl:top-[17.5%] leading-tight">
          Swipe.
          <br />
          Match.
          <br />
          Play.
        </div>
      </div>
      {/* Girl with Dog */}
      <div className="md:w-[70%]">
        <img src="/assets/hero-section/engagezone-bg.png" className="w-full bg-cover" alt="" />
      </div>
      <div></div>
    </div>
  );
};

export default EngageZoneSection;
