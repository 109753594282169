import React from "react";

// Files Import
import { content } from "../../../data";
import StyledH2Heading from "../../../common/components/StyledH2Heading/StyledH2Heading";
import StyledH5Heading from "../../../common/components/StyledH5Heading/StyledH5Heading";

const whyUsSection = () => {
  const whyUs = content.whyUs;
  return (
    <div className="flex flex-col items-center justify-center w-11/12 py-16 mx-auto lg:w-full ">
      <div className="flex flex-col items-center pb-10 text-center">
        <StyledH2Heading
          fontColor={"text-black"}
          content={"Why choose Paw Play Love"}
        />
        <p className="text-sm leading-normal text-grey md:text-lg lg:text-xl sm:w-2/3">
          At Paw Play Love, we're dedicated to creating meaningful connections
          for your furry friends.
        </p>
      </div>
      <div className="flex flex-col items-center gap-5 lg:flex-row lg:justify-between lg:gap-0">
        <div className="flex justify-end w-1/2 min-w-fit lg:min-w-min">
          <img src="/assets/hero-section/iPhone.svg" alt="Mobile" />
        </div>
        <div className="flex flex-col gap-5 lg:w-2/4">
          {whyUs.map((item, index) => (
            <div key={index} className="mx-auto md:w-3/4 lg:w-4/5">
              <StyledH5Heading fontColor={"text-black"} content={item.title} />
              <p className="text-grey">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default whyUsSection;
