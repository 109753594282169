import React, { useState, useEffect } from "react";

// Files Import
import Button from "../Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contactUsApi } from "../../apis/contactUsApi";
import GradientLayout from "../Gradients/GradientLayout/GradientLayout";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export default function ContactUs() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  useEffect(() => {
    const anyFieldEmpty =
      formData.firstName.length < 3 ||
      formData.lastName.length < 3 ||
      !emailRegex.test(formData.email) ||
      formData.subject.length < 5;

    setIsDisabled(anyFieldEmpty);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "firstName" || name === "lastName") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.length >= 3 ? "" : `Minimum characters 3 for ${name}`,
      }));
    } else if (name === "email") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailRegex.test(value) ? "" : "Invalid email",
      }));
    } else if (name === "subject") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
      }));
    } else if (name === "message") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.length >= 5 ? "" : `Minimum characters 5 for ${name}`,
      }));
    }
  };
  const handleSubmit = async () => {
    setIsDisabled(true);
    setIsSending(true);
    const res = await contactUsApi(formData);
    toast.success(res?.data?.message);
    setIsSending(false);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <GradientLayout>
      <div className="flex flex-col w-[100%] md:w-[80%] gap-6 py-6">
        <div className="flex flex-col gap-2">
          <p className="text-xl font-bold text-black">Fill in the form</p>
          <hr />
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-4 w-[100%]">
          <div className="flex flex-col w-full md:w-[48%] gap-1">
            <input
              placeholder="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
              maxLength={20}
            />
            {errors.firstName && (
              <p className="text-sm text-red-500">{errors.firstName}</p>
            )}
          </div>
          <div className="flex flex-col w-full md:w-[48%] gap-1">
            <input
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
              maxLength={20}
            />
            {errors.lastName && (
              <p className="text-sm text-red-500">{errors.lastName}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4">
          <div className="flex flex-col gap-1">
            <input
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
              type="email"
              maxLength={52}
            />
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <input
              placeholder="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="flex p-3 rounded-md shadow-lg focus:outline-none"
              maxLength={20}
            />
            {errors.subject && (
              <p className="text-sm text-red-500">{errors.subject}</p>
            )}
          </div>
          <textarea
            rows="10"
            placeholder="Your Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="flex p-3 rounded-md shadow-lg focus:outline-none"
            maxLength={500}
          />
          {errors.message && (
            <p className="text-sm text-red-500">{errors.message}</p>
          )}
        </div>
        <div className="flex self-center">
          <Button
            isDisabled={isDisabled}
            text={`${isSending ? " Sending..." : "Send Message"}`}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </GradientLayout>
  );
}
