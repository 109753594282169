import React from "react";

// Files Import
import AppStore from "../../../components/AppStore/AppStore";
import StyledH2Heading from "../../../common/components/StyledH2Heading/StyledH2Heading";
import GetAppGradient from "../../../components/Gradients/GetAppGradient/GetAppGradient";

const GetAppSection = () => {
  return (
    <GetAppGradient>
      <div className="relative lg:h-[378px] mx-auto flex flex-col lg:flex-row items-center flex-wrap gap-10 mt-1 lg:mt-10">
        <div className="flex flex-col items-center lg:items-start gap-5 w-11/12 lg:w-2/4 pt-14 lg:pt-0 lg:ml-[10%] lg:my-24">
          <div className="text-center lg:text-start">
            <StyledH2Heading
              fontColor={"text-white"}
              content={"Get the mobile app"}
            />
            <p className="font-medium text-white">
              Bringing Pet Connections to Your Fingertips
            </p>
          </div>
          <AppStore />
        </div>
        <div className="bottom-0 right-0 lg:absolute lg:w-2/4 xl:w-fit">
          <img src="/assets/mobile-app.png" alt="Mobile App" />
        </div>
      </div>
    </GetAppGradient>
  );
};

export default GetAppSection;
