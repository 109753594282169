import React from "react";

// Files Import
import CardsSection from "../../sections/Home/CardsSection/CardsSection";
import WhyUsSection from "../../sections/Home/WhyUsSection/WhyUsSection";
import HeroSection from "../../sections/Home/DiscoverSection/HeroSection";
import GetAppSection from "../../sections/Home/GetAppSection/GetAppSection";
import HomeFaqSection from "../../sections/Home/HomeFaqSection/HomeFaqSection";
import EngageZoneSection from "../../sections/Home/EngageZoneSection/EngageZoneSection";

export default function HomePage() {
  return (
    <div className="mx-auto">
      <HeroSection />
      <WhyUsSection />
      <EngageZoneSection />
      <CardsSection />
      <HomeFaqSection />
      <GetAppSection />
    </div>
  );
}
