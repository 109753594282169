import React from "react";

// Files Import
import StyledH5Heading from "../../../common/components/StyledH5Heading/StyledH5Heading";
import StyledH4Heading from "../../../common/components/StyledH4Heading/StyledH4Heading";

const PawPlayBond = () => {
  const styles = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "url('/assets/paw-play-bond-bg.png')",
  };
  return (
    <div
      style={styles}
      className="flex flex-col items-center justify-center w-full gap-10 py-16 md:py-32"
    >
      <div className="flex justify-center">
        <p className="self-start hidden text-white md:flex text-8xl -mt-9">“</p>
        <StyledH5Heading
          fontColor={
            "text-white capitalize text-center w-11/12 md:w-4/5 lg:w-[70%]"
          }
          content={
            <div className="relative">
              Through our app, pet owners can swipe through profiles of fellow
              pets lovers and their furry companions, seeking out potential
              playdates, friendships, and even soulmates for their pets. Whether
              it’s organizing a meet-up at the
              <br />
              local dog park or simply connecting over shared pet stories, Paw
              Play Love provides a safe and welcoming space for pet owner to
              come together and enrich the lives of their beloved animals.
            </div>
          }
        />
        <p className="self-end hidden -mb-8 text-white rotate-180 md:flex text-8xl">
          “
        </p>
      </div>
      <StyledH4Heading
        fontColor={"text-white capitalize"}
        content={"-Paw Play Love"}
      />
    </div>
  );
};

export default PawPlayBond;
