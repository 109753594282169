import React from "react";
import FaqSection from "../../sections/FaqSection/FaqSection";

export default function FaqPage() {
  return (
    <div className="mx-auto">
      <FaqSection />
    </div>
  );
}
