import React from "react";
import PrivacyPolicySection from "../../sections/PrivacyPolicySection/PrivacyPolicySection";

const PrivacyPolicy = () => {
  return (
    <div>
      <PrivacyPolicySection />
    </div>
  );
};

export default PrivacyPolicy;
