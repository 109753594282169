import "./App.css";
import Router from "./router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import { PathProvider } from "./context/PathContext";

function App() {
  return (
    <BrowserRouter>
      <PathProvider>
        <ToastContainer />
        <Header />
        <Router />
        <Footer />
      </PathProvider>
    </BrowserRouter>
  );
}

export default App;
