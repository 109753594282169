import React from "react";

// Files Import
import { Link } from "react-router-dom";
import StyledMdText from "../../common/components/StyledMdText/StyledMdText";
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";
import StyledH6Heading from "../../common/components/StyledH6Heading/StyledH6Heading";

const PrivacyPolicySection = () => {
  return (
    <div className="py-10 mx-auto">
      <div className="flex pt-8 w-11/12 md:w-3/4 lg:w-[62%] mx-auto">
        <img src="/assets/policy-pets.png" alt="Policy Pets" />
      </div>
      <div className="py-4 text-center md:py-5">
        <StyledH2Heading fontColor={"text-black"} content={"Privacy Policy"} />
      </div>

      <div className="bg-lightGrey space-y-5 rounded-[18px] py-10 mx-auto w-11/12 md:w-3/4 lg:w-[62%]">
        {/* Article 1 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 1 - DEFINITIONS:"}
          />

          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="flex flex-col gap-5">
                <div>
                  <b> a) APPLICABLE MOBILE APPLICATION:</b> This Privacy Policy
                  will refer to and be applicable to the Mobile App listed
                  above, which shall hereinafter be referred to as Mobile App.
                </div>
                <div>
                  <b>b) EFFECTIVE DATE:</b> "Effective Date" means the date this
                  Privacy Policy comes into force and effect.
                </div>
                <div>
                  <b>c) PARTIES:</b> The parties to this privacy policy are the
                  following data controller: Paw Play Love, LLC ("Data
                  Controller") and you, as the user of this Mobile App.
                  Hereinafter, the parties will individually be referred to as
                  "Party" and collectively as "Parties."
                </div>
                <div>
                  <b>d) DATA CONTROLLER:</b> Data Controller is the publisher,
                  owner, and operator of the Mobile App and is the Party
                  responsible for the collection of information described
                  herein. Data Controller shall be referred to either by Data
                  Controller's name or "Data Controller," as listed above. If
                  Data Controller or Data Controller's property shall be
                  referred to through first-person pronouns, it shall be through
                  the use of the following: us, we, our, ours, etc.
                </div>
                <div>
                  <b>e) YOU:</b> Should you agree to this Privacy Policy and
                  continue your use of the Mobile App, you will be referred to
                  herein as either you, the user, or if any second-person
                  pronouns are required and applicable, such pronouns as 'your",
                  "yours", etc.
                </div>
                <div>
                  <b>f) SERVICES:</b> "Services" means any services that we make
                  available for sale on the Mobile App.
                </div>
                <div>
                  <b>g) PERSONAL DATA:</b> "Personal DATA" means personal data
                  and information that we obtain from you in connection with
                  your use of the Mobile App that is capable of identifying you
                  in any manner.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 2 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 2 - GENERAL INFORMATION:"}
          />

          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="flex flex-col gap-5">
                <div>
                  This privacy policy (hereinafter "Privacy Policy") describes
                  how we collect and use the Personal Data that we receive about
                  you, as well as your rights in relation to that Personal Data,
                  when you visit our Mobile App or use our Services.
                </div>
                <div>
                  This Privacy Policy does not cover any information that we may
                  receive about you through sources other than the use of our
                  Mobile App. The Mobile App may link out to other websites or
                  mobile applications, but this Privacy Policy does not and will
                  not apply to any of those linked websites or applications.
                </div>
                <div>
                  We are committed to the protection of your privacy while you
                  use our Mobile App.
                </div>
                <div>
                  By continuing to use our Mobile App, you acknowledge that you
                  have had the chance to review and consider this Privacy
                  Policy, and you acknowledge that you agree to it. This means
                  that you also consent to the use of your information and the
                  method of disclosure as described in this Privacy Policy. If
                  you do not understand the Privacy Policy or do not agree to
                  it, then you agree to immediately cease your use of our Mobile
                  App.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 3 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 3 - CONTACT:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  The Party responsible for the processing of your personal data
                  is as follows: Paw Play Love, LLC. The Data Controller may be
                  contacted as follows:
                </div>
                <div className="pl-0 font-bold md:pl-10">
                  10640 Pebble Cove Lane,
                  <br />
                  Boca Raton, FL 33498
                </div>
                <div>
                  The Data Controller and operator of the Mobile App are one and
                  the same.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 4 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 4 - LOCATION:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  Please be advised the data processing activities take place in
                  the United States, outside the European Economic Area. Data
                  may also be transferred to companies within the United States,
                  but will only be done so in a manner that complies with the
                  EU's General Data Protection Regulation or GDPR. The location
                  where the data processing activities take place is as follows:
                </div>
                <div className="pl-0 font-bold md:pl-10">
                  410 Terry Avenue North, Seattle, WA 98109-5210, U.S.A
                </div>
              </div>
            }
          />
        </div>
        {/* Article 5 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 5 - MODIFICATIONS AND REVISIONS:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  We reserve the right to modify, revise, or otherwise amend
                  this Privacy Policy at any time and in any manner. If we do
                  so, however, we will notify you and obtain your consent to the
                  change in processing. Unless we specifically obtain your
                  consent, any changes to the Privacy Policy will only impact
                  the information collected on or after the date of the change.
                  It is also your responsibility to periodically check this page
                  for any such modification, revision or amendment.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 6 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 6 - THE PERSONAL DATA WE RECEIVE FROM YOU:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  Depending on how you use our Mobile App, you will be subject
                  to different types of Personal Data collected and different
                  manners of collection:
                </div>
                <div className="pl-0 space-y-5 md:pl-10">
                  <div>
                    <b>a) Registered users:</b> You, as a user of the Mobile
                    App, may be asked to register in order to use the Mobile App
                    or to purchase the Services available for sale.
                  </div>
                  <div className="space-y-5">
                    <div>
                      During the process of your registration, we will collect
                      some of the following Personal Data from you through your
                      voluntary disclosure:
                    </div>
                    <div className="pl-0 md:pl-10">
                      Name, email address, physical address, telephone number,
                      Facebook profile address, Twitter username, TikTok handle,
                      school, job, location (if applicable)
                    </div>
                  </div>
                  <div className="space-y-5">
                    <div>Personal Data may be asked for in relation to:</div>
                    <div className="pl-0 space-y-5 md:pl-10">
                      <div>
                        <b>I)</b> Interaction with our representatives in any
                        way
                      </div>
                      <div>
                        <b>II)</b> Making purchases way
                      </div>
                      <div>
                        <b>III)</b> Receiving notifications by text message or
                        email about marketing way
                      </div>
                      <div>
                        <b>IV)</b> Receiving general emails from us
                      </div>
                    </div>
                  </div>
                  <div>
                    By undergoing the registration process, you consent to us
                    collecting your Personal Data, including the Personal Data
                    described in this clause, as well as storing, using or
                    disclosing your Personal Data in accordance with this
                    Privacy Policy
                  </div>
                  <div>
                    <b>b) Unregistered users:</b> If you are a passive user of
                    the Mobile App and do not register for any purchases or
                    other service, you may still be subject to certain passive
                    data collection ("Passive Data Collection"). Such Passive
                    Data Collection may include through cookies, as described
                    below, IP address information, location information, and
                    certain browser data, such as history and/or session
                    information.
                  </div>
                  <div>
                    <b>c) All users:</b> The Passive Data Collection that
                    applies to Unregistered users shall also apply to all other
                    users and/or visitors of our Mobile App.
                  </div>
                  <div>
                    <b>d) Sales & Billing Information:</b> In order to purchase
                    any of the services on the Mobile App, you will be asked to
                    provide certain credit information, billing address
                    information, and possibly additional specific information so
                    that you may be properly charged for your purchases. This
                    payment and billing information may be stored for the
                    following period: 90 days. If so, it will be used
                    exclusively to assist you with making future purchases with
                    us.
                  </div>
                  <div className="space-y-5">
                    <div>
                      <b>e) Related Entities:</b> We may share your Personal
                      Data, including Personal Data that identifies you
                      personally, with any of our parent companies, subsidiary
                      companies, affiliates or other trusted related entities.
                    </div>
                    <div>
                      However, we only share your Personal Data with a trusted
                      related entity if that entity agrees to our privacy
                      standards as set out in this Privacy Policy and to treat
                      your Personal Data in the same manner that we do.
                    </div>
                  </div>
                  <div>
                    <b>f) Email Marketing:</b> You may be asked to provide
                    certain Personal Data, such as your name and email address,
                    for the purpose of receiving email marketing communications.
                    This information will only be obtained through your
                    voluntary disclosure and you will be asked to affirmatively
                    opt-in to email marketing communications.
                  </div>
                  <div>
                    <b>g) User Experience:</b> From time to time we may request
                    information from you to assist us in improving our Mobile
                    App, and the Services we sell, such as demographic
                    information or your particular preferences.
                  </div>
                  <div className="space-y-5">
                    <div>
                      {" "}
                      <b>h) Combined or Aggregated Information:</b> We may
                      combine or aggregate some of your Personal Data in order
                      to better serve you and to better enhance and update our
                      Mobile App for your and other consumers' use.
                    </div>
                    <div>
                      We may also share such aggregated information with others.
                    </div>
                  </div>
                  <div className="space-y-5">
                    <b>i) Other Information:</b> In addition to collecting the
                    Personal Data as already described herein, we may also
                    collect the following Personal Data:
                    <div className="pl-0 md:pl-10">
                      • Hobbies <br />
                      • Blood Type <br />
                      • Martial Status <br />• Vaccinations
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        {/* Article 7 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 7 - THE PERSONAL DATA WE RECEIVE AUTOMATICALLY:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  <b>Cookies:</b> We may collect information from you through
                  automatic tracking systems (such as information about your
                  browsing preferences) as well as through information that you
                  volunteer to us (such as information that you provide during a
                  registration process or at other times while using the Mobile
                  App, as described above).
                </div>
                <div>
                  <b>For example</b>, we use cookies to make your browsing
                  experience easier and more intuitive: cookies are small
                  strings of text used to store some information that may
                  concern the user, his or her preferences or the device they
                  are using to access the internet (such as a computer, tablet,
                  or mobile phone). Cookies are mainly used to adapt the
                  operation of the site to your expectations, offering a more
                  personalized browsing experience and memorizing the choices
                  you made previously.
                </div>
                <div>
                  A cookie consists of a reduced set of data transferred to your
                  browser from a web server and it can only be read by the
                  server that made the transfer. This is not executable code and
                  does not transmit viruses.
                </div>
                <div className="space-y-5">
                  <div>
                    Cookies do not record or store any Personal Data. If you
                    want, you can prevent the use of cookies, but then you may
                    not be able to use our Mobile App as we intend. To proceed
                    without changing the options related to cookies, simply
                    continue to use our Mobile App.
                  </div>
                  <div className="pl-0 space-y-5 md:pl-10">
                    <div>
                      <b>Technical cookies: </b>Technical cookies, which can
                      also sometimes be called HTML cookies, are used for
                      navigation and to facilitate your access to and use of the
                      site. They are necessary for the transmission of
                      communications on the network or to supply services
                      requested by you. The use of technical cookies allows the
                      safe and efficient use of the site.
                    </div>
                    <div>
                      You can manage or request the general deactivation or
                      cancelation of cookies through your browser. If you do
                      this though, please be advised this action might slow down
                      or prevent access to some parts of the site.
                    </div>
                    <div>
                      Cookies may also be retransmitted by an analytics or
                      statistics provider to collect aggregated information on
                      the number of users and how they visit the Mobile App.
                      These are also considered technical cookies when they
                      operate as described.
                    </div>
                    <div>
                      Temporary session cookies are deleted automatically at the
                      end of the browsing session - these are mostly used to
                      identify you and ensure that you don't have to log in each
                      time - whereas permanent cookies remain active longer than
                      just one particular session.
                    </div>
                    <div>
                      <b>Third-party cookies: </b>
                      We may also utilize third-party cookies, which are cookies
                      sent by a third-party to your computer. Permanent cookies
                      are often third-party cookies. The majority of third-party
                      cookies consist of tracking cookies used to identify
                      online behavior, understand interests and then customize
                      advertising for users.
                    </div>
                    <div>
                      Third-party analytical cookies may also be installed. They
                      are sent from the domains of the aforementioned third
                      parties external to the site. Third-party analytical
                      cookies are used to detect information on user behavior on
                      our Mobile App. This place anonymously, in order to
                      monitor the performance and improve the usability of the
                      site. Third-party profiling cookies are used to create
                      profiles relating to users, in order to propose
                      advertising in line with the choices expressed by the
                      users themselves.
                    </div>
                    <div>
                      <b>Profiling cookies: </b> We may also use profiling
                      cookies, which are those that create profiles related to
                      the user and are used in order to send advertising to the
                      user's browser.
                    </div>
                    <div>
                      When these types of cookies are used, we will receive your
                      explicit consent.
                    </div>
                    <div>
                      <b>Support in configuring your browser: </b>
                      You can manage cookies through the settings of your
                      browser on your device. However, deleting cookies from
                      your browser may remove the preferences you have set for
                      this Mobile App.
                    </div>
                    <div className="space-y-5">
                      <div>
                        For further information and support, you can also visit
                        the specific help page of the web browser you are using:
                      </div>
                      <div className="space-y-5 md:pl-5">
                        <div className="flex flex-col">
                          <b>- Internet Explorer:</b>{" "}
                          <Link
                            className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                            to={
                              "http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                            }
                          >
                            http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies
                          </Link>
                        </div>
                        <div className="flex flex-col">
                          <b>- Firefox:</b>{" "}
                          <Link
                            className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                            to={
                              "https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences"
                            }
                          >
                            https://support.mozilla.org/en-us/kb/enable-and-disable-cookies-website-preferences
                          </Link>
                        </div>
                        <div className="flex flex-col">
                          <b>- Safari:</b>{" "}
                          <Link
                            className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                            to={"http://www.apple.com/legal/privacy/"}
                          >
                            http://www.apple.com/legal/privacy/
                          </Link>
                        </div>
                        <div className="flex flex-col">
                          <b>- Chrome:</b>{" "}
                          <Link
                            className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                            to={
                              "https://support.google.com/accounts/answer/61416?hl=en"
                            }
                          >
                            https://support.google.com-accounts/answer/61416?hl=en
                          </Link>
                        </div>
                        <div className="flex flex-col">
                          <div>
                            <b>- Opera:</b>
                          </div>{" "}
                          <div>
                            <Link
                              className="text-[#0035b1d5] hover:text-[#6466dd] underline md:pl-5"
                              to={
                                "http://www.opera.com/help/tutorials/security/cookies/"
                              }
                            >
                              http://www.opera.com/help-tutorials/security/cookies/
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-5">
                      <div>
                        <b>Log Data: </b>Like all websites and mobile
                        applications, this Mobile App also makes use of log
                        files that store automatic information collected during
                        user visits. The different types of log data could be as
                        follows:
                      </div>
                      <div className="pl-0 md:pl-10">
                        <div>- Internet protocol (IP) address;</div>
                        <div>
                          - Type of browser and device parameters used to
                          connect to the Mobile App;
                        </div>
                        <div>
                          - Name of the Internet Service Provider (ISP);
                        </div>
                        <div>- Date and time of visit;</div>
                        <div>
                          - Web page of origin of the user (referral) and exit;
                        </div>
                        <div>- Possibly the number of clicks.</div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div>
                  The aforementioned information is processed in an automated
                  form and collected in an exclusively aggregated manner in
                  order to verify the correct functioning of the site, and for
                  security reasons. This information will be processed according
                  to the legitimate interests of the Data Controller.
                </div>
                <div>
                  For security purposes (spam filters, firewalls, virus
                  detection), the automatically recorded data may also possibly
                  include Personal Data such as IP address, which could be used,
                  in accordance with applicable laws, in order to block attempts
                  at damage to the Mobile App or damage to other users, or in
                  the case of harmful activities or crime. Such data are never
                  used for the identification or profiling of the user, but only
                  for the protection of the Mobile App and our users. Such
                  information will be treated according to the legitimate
                  interests of the Data Controller.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 8 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 8 - THIRD PARTIES:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  We may utilize third-party service providers ("Third-Party
                  Service Providers"), from time to time or all the time, to
                  help us with our Mobile App, and to help serve you.
                </div>
                <div className="pl-0 space-y-5 md:pl-10">
                  <div>
                    We may use Third-Party Service Providers to assist with
                    information storage (such as cloud storage).
                  </div>
                  <div>
                    We may provide some of your Personal Data to Third-Party
                    Service Providers in order to help us track usage data, such
                    as referral websites, dates and times of page requests, etc.
                    We use this information to understand patterns of usage of,
                    and to improve, the Mobile App.
                  </div>
                  <div>
                    We may use Third-Party Service Providers to host the Mobile
                    App. In this instance, the Third-Party Service Provider will
                    have access to your Personal Data.
                  </div>
                  <div>
                    We may allow third parties to advertise on the Mobile App.
                    These third parties may use cookies in connection with their
                    advertisements (see the "Cookies" clause in this Privacy
                    Policy).
                  </div>
                  <div>
                    We only share your Personal Data with a Third-Party Service
                    Provider if that provider agrees to our privacy standards as
                    set out in this Privacy Policy.
                  </div>
                  <div>
                    Notwithstanding the other provisions of this Privacy Policy,
                    we may provide your Personal Data to a third party or to
                    third parties in order to protect the rights, property or
                    safety, of us, our customers or third parties, or as
                    otherwise required by law.
                  </div>
                  <div className="space-y-5">
                    <div>
                      We may also share your Personal Data with the following
                      third parties:
                    </div>
                    <div className="pl-0 font-bold md:pl-10">
                      Government Organizations <br />
                      Private Businesses
                    </div>
                  </div>
                  <div>
                    We will not knowingly share your Personal Data with any
                    third parties other than in accordance with this Privacy
                    Policy.
                  </div>
                  <div>
                    If your Personal Data might be provided to a third party in
                    a manner that is other than as explained in this Privacy
                    Policy, you will be notified. You will also have the
                    opportunity to request that we not share that information.
                  </div>
                  <div>
                    In general, you may request that we do not share your
                    Personal Data with third parties. Please contact us via
                    email, if so. Please be advised that you may lose access to
                    certain services that we rely on third-party providers for.
                  </div>
                </div>
              </div>
            }
          />
        </div>
        {/* Article 9 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 9 - HOW PERSONAL DATA IS STORED:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  We use secure physical and digital systems to store your
                  Personal Data when appropriate. We ensure that your Personal
                  Data is protected against unauthorized access, disclosure, or
                  destruction.
                </div>
                <div>
                  Please note, however, that no system involving the
                  transmission of information via the internet, or the
                  electronic storage of data, is completely secure. However, we
                  take the protection and storage of your Personal Data very
                  seriously. We take all reasonable steps to protect your
                  Personal Data.
                </div>
                <div className="space-y-5">
                  <div>
                    The systems that we use to store your information include
                    but are not limited to:
                  </div>
                  <div className="pl-0 font-semibold md:pl-10">
                    We ensure that only a handful of staff members (not more
                    than 5) have access to personal user information data, and
                    we use an outside monitoring service.
                  </div>
                </div>
                <div>
                  Personal Data is stored throughout your relationship with us.
                  We delete your Personal Data upon request for cancelation of
                  your account or other general request for the deletion of
                  data.
                </div>
                <div>
                  In the event of a breach of your Personal Data, you will be
                  notified in a reasonable time frame, but in no event later
                  than two weeks, and we will follow all applicable laws
                  regarding such breach.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 10 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 10 - PURPOSES OF PROCESSING OF PERSONAL DATA:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  We primarily use your Personal Data to help us provide a
                  better experience for you on our Mobile App and to provide you
                  the services and/or information you may have requested, such
                  as use of our Mobile App.
                </div>
                <div>
                  Information that does not identify you personally, but that
                  may assist in providing us broad overviews of our customer
                  base, will be used for market research or marketing efforts.
                  Such information may include, but is not limited to, interests
                  based on your cookies.
                </div>
                <div className="space-y-5">
                  <div>
                    Personal Data that may be considering identifying may be
                    used for the following:
                  </div>
                  <div className="pl-0 space-y-5 md:pl-10">
                    <div>a) Improving your personal user experience</div>
                    <div>
                      b) Communicating with you about your user account with us
                    </div>
                    <div>
                      c) Marketing and advertising to you, including via email
                    </div>
                    <div>d) Fulfilling your purchases</div>
                    <div>e) Providing customer service to you</div>
                    <div>
                      f) Advising you about updates to the Mobile App or related
                      Items
                    </div>
                    <div className="space-y-5">
                      <div>g) The following other purposes:</div>
                      <div className="pl-0 font-semibold md:pl-10">
                        We collect user information to share with government
                        agencies to assist them with law enforcement and to
                        share with personal businesses to help research.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        {/* Article 11 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 11 - DISCLOSURE OF PERSONAL DATA:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div className="space-y-5">
                  <div>
                    Although our policy is to maintain the privacy of your
                    Personal Data as described herein, we may disclose your
                    Personal Data if we believe that it is reasonable to do so
                    in certain cases, in our sole and exclusive discretion. Such
                    cases may include, but are not limited to:
                  </div>
                  <div className="pl-0 space-y-5 md:pl-10">
                    <div>
                      a) To satisfy any local, state, or Federal laws or
                      regulations
                    </div>
                    <div>
                      b) To respond to requests, such discovery, criminal,
                      civil, or administrative process, subpoenas, court orders,
                      or writs from law enforcement or other governmental or
                      legal bodies
                    </div>
                    <div>
                      c) To bring legal action against a user who has violated
                      the law or violated the terms of use of our Mobile App
                    </div>
                    <div>
                      d) As may be necessary for the operation of our Mobile App
                    </div>
                    <div>
                      e) To generally cooperate with any lawful investigation
                      about our users
                    </div>
                    <div>
                      f) If we suspect any fraudulent activity on our Mobile App
                      or if we have noticed any activity which may violate our
                      terms or other applicable rules
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        {/* Article 12 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 12 - PUBLIC INFORMATION:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  We may allow users to post their own content or information
                  publicly on our Mobile App. Such content or information may
                  include, but is not limited to, photographs, status updates,
                  blogs, articles, or other personal snippets. Please be aware
                  that any such information or content that you may post should
                  be considered entirely public and that we do not purport to
                  maintain the privacy of such public information.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 13 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 13 - OPTING OUT OF TRANSMITTALS FROM US:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  From time to time, we may send you informational or marketing
                  communications related to our Mobile App such as announcements
                  or other information. If you wish to opt-out of such
                  communications, you may contact the following email:
                  info@pawplaylove.com. You may also click the opt-out link
                  which will be provided at the bottom of any and all such
                  communications.
                </div>
                <div>
                  Please be advised that even though you may opt-out of such
                  communications, you may still receive information from us that
                  is specifically about your use of our Mobile App or about your
                  account with us.
                </div>
                <div>
                  By providing any Personal Data to us, or by using our Mobile
                  App in any manner, you have created a commercial relationship
                  with us. As such, you agree that any email sent from us or
                  third-party affiliates, even unsolicited email, shall
                  specifically not be considered SPAM, as that term is legally
                  defined.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 14 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={
              "Article 14 - MODIFYING, DELETING, AND ACCESSING YOUR INFORMATION:"
            }
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  If you wish to modify or delete any information we may have
                  about you, or you wish to simply access any information we
                  have about you, you may do so from your account settings page.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 15 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 15 - ACCEPTANCE OF RISK:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  By continuing to our Mobile App in any manner, use the
                  Product, you manifest your continuing asset to this Privacy
                  Policy. You further acknowledge, agree and accept that no
                  transmission of information or data via the internet is not
                  always completely secure, no matter what steps are taken. You
                  acknowledge, agree and accept that we do not guarantee or
                  warrant the security of any information that you provide to
                  us, and that you transmit such information at your own risk.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 16 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 16 - YOUR RIGHTS:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div className="space-y-5">
                  <div>
                    You have many rights in relation to your Personal Data.
                    Specifically, your rights are as follows
                  </div>
                  <div className="pl-0 space-y-5 md:pl-10">
                    <div>
                      - The right to be informed about the processing of your
                      Personal Data
                    </div>
                    <div>- The right to have access to your Personal Data</div>
                    <div>
                      - The right to update and/or correct your Personal Data
                    </div>
                    <div>- The right to portability of your Personal Data</div>
                    <div>- The right to oppose or limit the processing of your Personal Data</div>
                    <div>- The right to request that we stop processing and delete your Personal Data</div>
                    <div>- The right to block any Personal Data processing in violation of any applicable law</div>
                    <div>
                      - The right to launch a complaint with the Federal Trade
                      Commission (FTC) in the United States or applicable data
                      protection authority in another jurisdiction
                    </div>
                  </div>
                </div>
                <div>
                  Such rights can all be exercised by contacting us at the
                  relevant contact information listed in this Privacy Policy.
                </div>
              </div>
            }
          />
        </div>
        {/* Article 17 */}
        <div className="w-11/12 md:w-[85%] mx-auto flex flex-col gap-2 md:gap-4">
          <StyledH6Heading
            fontColor={"text-black"}
            content={"Article 17 - CONTACT INFORMATION:"}
          />
          <StyledMdText
            fontColor={"text-darkGrey"}
            content={
              <div className="space-y-5">
                <div>
                  If you have any questions about this Privacy Policy or the way
                  we collect information from you, or if you would like to
                  launch a complaint about anything related to this Privacy
                  Policy, you may contact us at the following email address:{" "}
                  <Link
                    className="text-[#0035b1d5] hover:text-[#6466dd] underline"
                    to={"mailto:info@pawplaylove.com"}
                  >
                    info@pawplaylove.com
                  </Link>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicySection;
