const GradientLayout = ({ children }) => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex flex-col w-11/12 md:w-3/4 lg:w-[62%] justify-center items-center rounded-lg bg-layout-purple-gradient p-4">
        {children}
      </div>
    </div>
  );
};
export default GradientLayout;
