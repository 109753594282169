import React from "react";

const AboutHeroSection = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <img src="/assets/about-dogs.svg" className="w-full bg-cover" alt="Dog's" />
    </div>
  );
};

export default AboutHeroSection;
