import React from "react";

const Copyrights = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <p className="text-xs font-normal text-center text-white opacity-70">
        &#169; {currentYear} Paw Play Love&trade;. All Rights Reserved.
      </p>
    </div>
  );
};

export default Copyrights;
