import React from "react";

// Files Import
import { Link } from "react-router-dom";
import StyledH2Heading from "../../common/components/StyledH2Heading/StyledH2Heading";

const NotFoundSection = () => {
  return (
    <div className="mx-auto w-11/12 flex flex-col justify-center items-center gap-5 md:gap-7 h-[90vh]">
      <img src="/assets/404.svg" alt="404 Not Found" />

      <div className="flex flex-col items-center gap-1">
        <StyledH2Heading fontColor="text-black" content="Oops!" />
        <p className="sm:text-xl font-medium">Error, Page Not Found</p>
      </div>

      <Link to="/">
        <button className="text-[#7738F3] border-2 border-[#7738F3] cursor-pointer rounded-full py-3 px-6 text-md leading-[120%] text-center font-semibold transition duration-300 ease-in-out hover:scale-105">
          Go back home
        </button>
      </Link>
    </div>
  );
};

export default NotFoundSection;
