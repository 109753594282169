import React from "react";
import SupportSection from "../../sections/SupportSection/SupportSection";

export default function SupportPage() {
  return (
    <div className="mx-auto ">
      <SupportSection />
    </div>
  );
}
