import React from "react";

const SearchBar = ({ onChange, value }) => {
  return (
    <div className="relative pt-5 w-11/12 sm:w-9/12 md:w-8/12 lg:w-3/5 mx-auto">
      <div className="flex">
        <input
          className="w-full bg-white border border-grey rounded-xl py-3 pl-5 pr-4 focus:outline-none"
          maxLength={60}
          placeholder="Search here"
          type="text"
          onChange={onChange}
          value={value}
        />
        <div className="border-r border-grey h-6 absolute bottom-3 right-11"></div>
        <span className="absolute bottom-3 right-0 flex items-center pr-3 cursor-pointer">
          <svg
            className="h-6 w-6 fill-grey"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"></path>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
